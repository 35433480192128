




import { Vue, Component } from 'vue-property-decorator'

/** Dummy component used as a placeholder for SSR */
@Component({})
export default class Empty extends Vue {
  public mounted () {

  }
}
